import { render, staticRenderFns } from "./Mandrill.vue?vue&type=template&id=310934a6&scoped=true&"
import script from "./Mandrill.vue?vue&type=script&lang=js&"
export * from "./Mandrill.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "310934a6",
  null
  
)

export default component.exports