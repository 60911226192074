<template>
    <div>
        <app-table id="export-table" :options="options" />
        <export-modal :modal-id="exportModalId" v-if="isConfirmationModalActive" @close-modal="closeModal">
            <form class="mb-0" ref="form" enctype="multipart/form-data">
                <div>
                    <div class="note-title d-flex">
                        <app-icon name="book-open" />
                        <h6 class="card-title pl-2">{{ $t("remember") }}</h6>
                    </div>
                    <div class="note note-warning p-4 mb-2">
                        <ol style="list-style: none;">
                            <li class="my-1"> {{ $t("csv_export_guide1") }}</li>
                        </ol>
                    </div>

                    <div class="my-primary">
                        <label for="fields">{{ $t('select_type') }}</label>
                        <div class="d-flex flex-column g-1">
                            <app-input v-model="formData.all" type="checkbox"
                                :list="[{ id: 'all_data', value: $t('all_data') }]" radioCheckboxWrapper="d-flex g-1"
                                @changed="allChange" />
                                <hr>
                            <app-input v-model="formData.fields" type="checkbox" :list="list"
                                radioCheckboxWrapper="custom-input-wrapper" @input="inputListValue" />
                        </div>
                    </div>
                </div>
            </form>
            <!-- <div class="text-center big-icon mt-primary" :key="iconKey">
                    <app-icon :name="'download'" class="text-primary" />
                </div>
                <h5 class="text-center font-weight-bold mt-4">{{ $t('are_you_sure') }}</h5>
                <p class="mb-primary text-center font-size-90 p-0"> {{ $t('are_you_sure_you_want_to_download_data') }}</p> -->
                <div class="note note-warning d-inline-block p-3 mb-primary">
                    <div class="d-flex --justify-content-center gap-2">
                        <div class="d-flex align-items-center">
                            <span class="d-flex px-3 py-2 form-control">{{ equation.field1 }}</span>
                            <span class="d-flex px-3 py-2 --form-control">+</span>
                            <span class="d-flex px-3 py-2 form-control">{{ equation.field2 }}</span>
                        </div>
                        <span class="d-flex px-3 py-2">=</span>
                        <input type="text" class="form-control" v-model="equation.result" :placeholder="$t('answer')"
                            style="width: 80px;">
                    </div>
                </div>
            <div>
                <button class="btn btn-secondary mr-2" data-dismiss="modal" @click.prevent="closeModal">{{
            $t('no') }}</button>
                <button class="btn btn-primary" @click.prevent="confirm"
                    :disabled="preloader || !([...formData.all, ...formData.fields].length) || ((equation.field1 + equation.field2)?.toString() !== equation.result?.toString())">{{
            $t('export') }} </button>
            </div>
        </export-modal>
    </div>
</template>

<script>
import { FormMixin } from "../../../../../core/mixins/form/FormMixin";
import ExportModal from "./ExportModal.vue";
import { EXPORT_MODULES } from "../../../../Config/ApiUrl";
import { axiosPost } from "../../../../../common/Helper/AxiosHelper";
import { localTimeZone } from "../../../../../common/Helper/Support/DateTimeHelper";

export default {
    mixins: [FormMixin],
    components: { ExportModal },
    data() {
        return {
            options: {
                name: 'exports',
                url: EXPORT_MODULES,
                columns: [
                    {
                        title: this.$t('users'),
                        type: 'component',
                        key: 'progress',
                        isVisible: true,
                        componentName: 'app-export-item-component',
                    }
                ],
                datatableWrapper: false,
                showHeader: false,
                tableShadow: false,
                showSearch: false,
                showFilter: false,
                paginationType: "loadMore",
                responsive: true,
                rowLimit: 5,
                showAction: true,
                actionType: "default",
                actions: [],
                // afterRequestSuccess: ({data}) => {}
            },
            preloader: false,
            exportModalId: 'export-modal',
            list: [
                { id: 'holiday', value: this.$t('modules_holiday') },
                { id: 'leave_type', value: this.$t('modules_leave_type') },
                { id: 'employee', value: this.$t('modules_employee') },
                { id: 'employment_history', value: this.$t('modules_employment_history') },
                { id: 'designation_history', value: this.$t('modules_designation_history') },
                { id: 'department_history', value: this.$t('modules_department_history') },
                { id: 'work_shift', value: this.$t('modules_work_shift') },
                { id: 'attendance', value: this.$t('modules_attendance') },
                { id: 'leave', value: this.$t('modules_leave') },
                { id: 'salary_history', value: this.$t('modules_salary_history') },
                { id: 'department', value: this.$t('department') },
                { id: 'leave_status_history', value: this.$t('leave_status_history') },
            ],
            formData: {
                fields: [
                    'holiday',
                    'leave_type',
                    'employee',
                    'employment_history',
                    'designation_history',
                    'department_history',
                    'work_shift',
                    'attendance',
                    'leave',
                    'salary_history',
                    'department',
                    'leave_status_history'
                ], all: ['all_data']
            },
            equation: { field1: this.randomValue(1, 10), field2: this.randomValue(1, 10) },
            isConfirmationModalActive: false,
            errorMessage: '',
            stage: 'init',
            iconKey: 0,
            title: this.$t('are_you_sure'),
        }
    },
    methods: {
        randomValue(min, max) {
            return Math.round(Math.random() * (max - min) + min);
        },
        allChange(e) {
            if (e.target.checked) {
                this.formData.fields = this.list.map(i => i.id)
            } else {
                this.formData.fields = []
            }
        },
        inputListValue(e) {
            let allList = !!(!this.list.map(i => i.id).filter(i => !e.includes(i)).length)
            if (allList) {
                this.formData.all = ['all_data']
            } else {
                this.formData.all = []
            }
        },
        exportHandler() {
            this.isConfirmationModalActive = true
            this.equation = { field1: this.randomValue(1, 10), field2: this.randomValue(1, 10) }
        },
        confirm() {
            this.preloader = true;
            this.stage = 'process'
            this.iconKey++
            axiosPost(EXPORT_MODULES + '?timeZone=' + localTimeZone, { fields: [...this.formData.all, ...this.formData.fields] }).then(({ data }) => {
                this.$toastr.s(data.message);
                this.closeModal()
            }).catch(({ response }) => {
                console.log('err', response)
                this.errorMessage = response.data.message;
                this.iconKey++;
            }).finally(() => {
                this.preloader = false;
            })
        },
        closeModal() {
            $(`#${this.exportModalId}`).modal('hide');
            this.isConfirmationModalActive = false
            this.equation = {}
            this.stage = 'init'
            this.$hub.$emit('reload-export-table');
        },
    },
    mounted() {
        this.$hub.$on('open-export-modal', () => {
            this.exportHandler()
            this.isConfirmationModalActive = true
        })
    },
};
</script>

<style>
.custom-input-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 10px;
}
</style>
