import { render, staticRenderFns } from "./StoreUpdateNotificationSettings.vue?vue&type=template&id=30fe5c2a&scoped=true&"
import script from "./StoreUpdateNotificationSettings.vue?vue&type=script&lang=js&"
export * from "./StoreUpdateNotificationSettings.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "30fe5c2a",
  null
  
)

export default component.exports