import { render, staticRenderFns } from "./EmployeeDashboard.vue?vue&type=template&id=04d7da08&scoped=true&"
import script from "./EmployeeDashboard.vue?vue&type=script&lang=js&"
export * from "./EmployeeDashboard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04d7da08",
  null
  
)

export default component.exports